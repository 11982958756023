import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div style={{ height: '94vh', backgroundImage: 'radial-gradient(circle at 100% 100%, #872424 0%, #9A2020 38%, #EF0E0E 82%)', marginBottom: '-2rem' }}>
      <Container style={{ height: '100%' }}>
        <Row className="align-items-center justify-content-center" style={{ height: '100%' }}>
          <Col className="text-center" style={{ color: 'white' }}>
            <h1 style={{ fontSize: '3rem' }}>404</h1>
            <p>Oh no! That page does not exist</p>
            <Link to="/">
              <Button className="white-button rounded-button">Home</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
)

export default NotFoundPage
